/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: "DM Sans-Regular", Helvetica !important;}
.style-error .mat-mdc-simple-snack-bar {
    background: #a50b0b !important;
}
.style-success .mat-mdc-simple-snack-bar {
    background: #29800a !important;
}
#wait{display: block;position: fixed;z-index: 999999;top: 0;left: 0;height: 100%;width: 100%;background: rgba( 255, 255, 255, .8 ) 50% 50% no-repeat;}
#wait img{margin-top:30%; margin-left:50%}
.mat-mdc-progress-spinner {
    display: block;
    overflow: hidden;
    line-height: 0;
    left: 50%;
    top: 50%;
}